import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Globals } from "@app/_helpers/globals";
import { ToastService } from '@app/_components/toast';

@Injectable()
export class Utils {
  title = 'Ingenious Plex';z
  currentPage = '';
  toastrDuration = 5000;
  jsTag: '<script src="http://beta-cookie.vrtzads.com/pixel/vsync.js" data-id="vrtzcon" data-token="dsarqwrfdx" data-vcs="109914648702" endpoint="http://beta-cookie.vrtzads.com/pixel/vsync?convId=109914648702&label=test123&order_id=[ORDER_ID]&order_value=[ORDER_VALUE]&rd=[REDIRECT_URL]&email=[EMAIL]&product=[PRODUCT_ID]&cb=[CB]&vpr=[VPR]"></script>'


  constructor(@Inject(DOCUMENT) public document: any, private globals: Globals, private router: Router, private titleService: Title, private toastService: ToastService) {
    // router.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd) {
    //     let temp = val.urlAfterRedirects.split('/');
    //     this.currentPage = temp[temp.length - 1].replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    //     this.setTitle(this.title + ' | ' + this.currentPage);
    //   }
    // });
  }

  csvToJSON(csvText) {
    let lines = [];
    const linesArray = csvText.split('\n');
    linesArray.forEach((e: any) => {
      const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim();
      lines.push(row);
    });
    lines.splice(lines.length - 1, 1);
    const result = [];
    if(!lines.length) return result;

    const headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    return result
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  setPageTitle(newTitle: string) {
    this.title = newTitle;
    this.setTitle(this.title + ' | ' + this.currentPage);
  }

  convertToCommaSeperatedValues(list) {
    return list.filter(record => record.selected).map(record => record.id).join(',');
  }

  redirectTo(path) {
    this.router.navigate([path]);
  }

  showToaster(message, title, type) {
    this.toastService.show({
      title: title,
      text: message,
      type: type,
    });
  }

  booleanListFilterWithKey(list, key) {
    return list.filter(record => record[key]);
  }

  CSVTOJSONFunction(csv) {
    var data = [];
    if (csv.length != 0) {
      var lines = csv.split("\n");
      var lineNumber = 0;
      var headers = lines[1].split(",");
      for (var i = 2; i < lines.length - 1; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        obj['entry_id'] = lineNumber++;
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        data.push(obj);
      }
      var totalCount = lines[0].split(",");
      if (totalCount[1] == "null") {
        totalCount = lineNumber;
      } else {
        totalCount = parseInt(totalCount[1]);
      }
    }
    return {data,totalCount};
  }

  toTitleCase = (phrase) => {
    return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  };

  public get isCopySupported(): boolean {
    return !!this.document.queryCommandSupported && !!this.document.queryCommandSupported('copy');
  }

  copyToClipboard(type, text: string) {
    if (this.isCopySupported) {
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text.length ? text : '-';
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.showToaster(`${type} copied to Clipboard`, `Suceess`, 'success');
    } else {
      this.showToaster(`Copy is not supported by this browser`, `Error`, 'danger');
    }
  }

  getFormattedDate(string) {
    let date = new Date(string);
    // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  }

  getFilterFormattedDate(string) { // yyyy=MM-dd
    let date = new Date(string),getMonth,getDate;
    if ((date.getMonth() + 1).toString().length == 1) {
      getMonth = ("0" + (date.getMonth() + 1));
    } else {
      getMonth = (date.getUTCMonth() + 1);
    }
    if (date.getDate().toString().length == 1) {
      getDate = ("0" + (date.getDate()));
    } else {
      getDate = (date.getDate());
    }
    // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    var formattedDate = date.getFullYear() + "-" + getMonth + "-" + getDate;
    // var formattedDate = new Date(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate())
    console.log("formattedDate===>",formattedDate)
    return formattedDate;

  }

  // getFormattedDate24HourTime(string) {
  //   let date = new Date(string);
  //   // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  //   return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  // }

  getReportFormatDate(string) {
    let date = new Date(string),getMonth, getDate;
    if ((date.getMonth() + 1).toString().length == 1) {
      getMonth = ("0" + (date.getMonth() + 1));
    } else {
      getMonth = (date.getMonth() + 1);
    }
    if (date.getDate().toString().length == 1) {
      getDate = ("0" + (date.getDate()));
    } else {
      getDate = (date.getDate());
    }
    // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return date.getFullYear() + "-" + getMonth + "-" + getDate;
  }

  // getFormattedDate24HourTime(string) {
  //   let date = new Date(string);
  //   // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  //   return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  // }


  getFormattedDateTime(string) {
    let dateFormat = require('dateformat');
    let date = new Date(string);
    let dateString = dateFormat(date, "yyyy-mm-dd HH:MM:ss");
    console.log("dateString==>",dateString)
    return dateString;
    // let date = new Date(string);
    // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }



  getUTCFormattedDateTime(string) {
    // let dateFormat = require('dateformat');
    // let dateString = dateFormat(date, "yyyy-mm-dd h:MM:ss");
    // let date = new Date(string)
    // console.log("dateString==>", date, date.getUTCFullYear() + "-" + date.getUTCMonth() + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds())
    let date = new Date(string);
    let dateString, getUTCDate, UTChour, getUTCMinutes, getUTCSeconds, getUTCMonth;
    if (date.getUTCHours().toString().length == 1){
      UTChour = ("0" + date.getUTCHours());
    } else {
      UTChour = (date.getUTCHours());
    }
    if (date.getUTCMinutes().toString().length == 1) {
      getUTCMinutes = ("0" + date.getUTCMinutes());
    } else {
      getUTCMinutes = (date.getUTCMinutes());
    }
    if (date.getUTCSeconds().toString().length == 1) {
      getUTCSeconds = ("0" + date.getUTCSeconds());
    } else {
      getUTCSeconds = (date.getUTCSeconds());
    }
    if ((date.getUTCMonth() + 1).toString().length == 1) {
      getUTCMonth = ("0" + (date.getUTCMonth() + 1));
    } else {
      getUTCMonth = (date.getUTCMonth() + 1);
    }
    if (date.getUTCDate().toString().length == 1) {
      getUTCDate = ("0" + (date.getUTCDate()));
    } else {
      getUTCDate = (date.getUTCDate());
    }
    // if (date.getUTCMonth().toString().length == 1 && date.getUTCDate().toString().length == 1) {
    //   dateString = date.getUTCFullYear() + "-" + ("0" + (date.getUTCMonth() + 1)) + "-" + ("0" + date.getUTCDate()) + " " + (UTChour) + ":" + getUTCMinutes + ":" + (getUTCSeconds);
    // } else if (date.getUTCDate().toString().length == 1)  {
    //   console.log("dateString true 1")
    //   dateString = date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + ("0" + date.getUTCDate()) + " " +  UTChour  + ":" + getUTCMinutes + ":" + getUTCSeconds;
    // } else if (date.getUTCMonth().toString().length == 1 ) {
    //   console.log("dateString true 2")
    //   dateString = date.getUTCFullYear() + "-" + ("0" + (date.getUTCMonth() + 1)) + "-" +  date.getUTCDate() + " " + ( UTChour ) + ":" + getUTCMinutes + ":" + getUTCSeconds;
    // } else {
    dateString = date.getUTCFullYear() + "-" + getUTCMonth + "-" + getUTCDate + " " +  UTChour  + ":" + getUTCMinutes + ":" + getUTCSeconds;
    // }
    return dateString;
    // let date = new Date(string);
    // return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  disableWhitespaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return { 'required': true };
    } else {
      return null;
    }
  }

  dropdownZeroValidation(control: AbstractControl) {
    if (control && control.value == 0) {
      return { 'required': true };
    } else {
      return null;
    }
  }

  urlValidation(control: AbstractControl) {
    var urlregex = /^(https?):\/\//;
    if (control && !urlregex.test(control.value)) {
      return { 'url': true };
    } else {
      return null;
    }
  }


  urlValidationwithoutHttps(control: AbstractControl) {
    var urlregex = /^(https?):\/\//;
    if (control && urlregex.test(control.value)) {
      return { 'url': true };
    } else {
      return null;
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  setLocalStorage(key, json) {
    localStorage.setItem(key, JSON.stringify(json));
  }

  getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  base64BlobToFileDownload(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;base64,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  stringToFileDownload(filename, text) {
    var element = document.createElement('a');
    console.log()
    element.setAttribute('href', 'data:text/plain,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  setCookie(name, value, days) {
    var domain, domainParts, date, expires, host;

    if (days) {
      date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    host = location.host;
    if (host.split('.').length === 1) {
      document.cookie = name + "=" + value + expires + "; path=/";
    } else {
      domainParts = host.split('.');
      domainParts.shift();
      domain = '.' + domainParts.join('.');
      document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain;

      if (this.getCookie(name) == null || this.getCookie(name) != value) {
        domain = '.' + host;
        document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain;
      }
    }
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i=0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  eraseCookie(name) {
    this.setCookie(name, '', -1);
  }
  //  getCookie(name) {
  //   var value = "; " + document.cookie;
  //   var parts = value.split("; " + name + "=");
  //   if (parts.length == 2) return parts.pop().split(";").shift();
  // }
  zohoChatBox(){

    // var Visit = getCookie("Visit");
    // console.log("groupID----->", groupID)
    // if (Visit) {
    var a = "test"/* decodeURIComponent(getCookie("userName")) */;
    var b = "test"/* decodeURIComponent(getCookie("userEmail")) */;
    var $zoho = $zoho || {}; $zoho.salesiq = $zoho.salesiq || { widgetcode: "545376e93d010b1823ca879a10fb8a9577b73f1e50f58dc7399185684c44f331", values: {}, ready: function () { } };
    var d = document; var s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true;
    s.src = "https://salesiq.zoho.in/widget"; var t = d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s, t); d.write("<div id='zsiqwidget'></div>");
    $zoho.salesiq.ready = function () {
      $zoho.salesiq.visitor.name(a)
      $zoho.salesiq.visitor.email(b);
    }
    // }
  }
}
