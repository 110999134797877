import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Globals } from "@app/_helpers/globals";
import { Utils } from "@app/_helpers/utils";
import { AuthService } from "@app/_services/auth.service";
import { ToastModule } from "@app/_components/toast";
import { AppComponent } from './app.component';
import { CustomizeMappingBoxComponent } from './_components/customize-mapping-box/customize-mapping-box.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule, PopoverModule } from 'ngx-bootstrap';
import { Country } from '@app/_helpers/ssp-report-json';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ListManagementActionComponent } from './_components/list-management-action/list-management-action.component';
// import { AdSize } from '@app/_helpers/ssp-report-json';
// import { operatingSystem } from '@app/_helpers/ssp-report-json';
// import { SpecificTargettingComponentComponent } from './_components/specific-targetting-component/specific-targetting-component.component';
// import { TrackConversionPipe } from './_helpers/track-conversion.pipe';
@NgModule({
  declarations: [
    AppComponent,
    CustomizeMappingBoxComponent,
    ListManagementActionComponent
    // SpecificTargettingComponentComponent,
    // TrackConversionPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    ToastModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),PopoverModule.forRoot()
    ///////////////////// NgxTagsInputModule,TagInputModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Utils,
    AuthService,
    Globals,
    Country/* ,
    AdSize,
    operatingSystem */,PopoverModule,TooltipModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
